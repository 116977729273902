@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}
body {
  font-family: 'Montserrat',sans-serif;
  min-height: 100vh;
}
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.badge {
  position: relative;
}

.badge::after {
  content: attr(data-count);
  position: absolute;
  text-align: center;
  top: -6px;
  left: 18px;
  font-size: 12px;
  padding: 0 6px;
  border-radius: 50%;
  color: white;
  background-color: red;
}

.product-item .product-name,
.cart .cart-list__item__info__name span,
.product-name__checkout {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.product-sale::after {
  content: '';
  position: absolute;
  top: 0;
  right: -9px;
  border-top: 12px solid red;
  border-right: 10px solid transparent;
  border-bottom: 12px solid red;
}

.product-heading-list::after {
  content: '';
  position: absolute;
  top: 0;
  right: -16px;
  border-left: 18px solid #f2f2f2;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

/* Banner */

.slick-arrow.slick-next {
  position: absolute;
  right: 30px;
  z-index: 1;
}

.slick-arrow.slick-prev {
  position: absolute;
  left: 10px;
  z-index: 1;
}

.slick-prev:before, .slick-next:before {
  font-size: 40px !important;
}

ul.slick-dots {
  bottom: 8px;
}

ul.slick-dots li button:before {
  font-family: 'slick';
  font-size: 14px;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  content: '•';
  text-align: center;
  opacity: .50;
  color: white
}

ul.slick-dots li.slick-active button:before {
  opacity: .90;
  color: white;
}

.product-sale-list ul.react-multi-carousel-dot-list {
  position: relative;
}

.product-list ul.react-multi-carousel-dot-list {
  position: relative;
}

.skeleton-product {
  width: 100%!important;
  height: 200px;
}

button.react-multiple-carousel__arrow {
  z-index: 50;
}